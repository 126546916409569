import { apiV1 } from "../axiosInstance";

function getProduct(productNumber) {
    return apiV1.get(`products/${productNumber}`);
};

function getRandomSaleForProduct(productNumber) {
    return apiV1.get(`products/${productNumber}/randomsale`);
};

function getProductClassificationTypes() {
    return apiV1.get(`products/classifications/types`);
};

function getProductRestrictions() {
    return apiV1.get(`products/restrictions`);
};

function getProductWizardRestrictions() {
    return apiV1.get(`products/restrictions/wizard`);
};

function getProductClone(productNumber) {
    return apiV1.get(`products/${productNumber}/clone`);
};

function saveProduct(obj) {
    return apiV1.post(`products/save`, obj);
};

function deleteProduct(productNumber) {
    return apiV1.delete(`products/product/${productNumber}`);
};

export  { 
    getProduct, 
    getRandomSaleForProduct, 
    getProductClassificationTypes,
    getProductRestrictions,
    getProductWizardRestrictions,
    getProductClone,
    saveProduct,
    deleteProduct
}