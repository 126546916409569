import { apiV1 } from "../axiosInstance";

function getSaleDefinitions() {
    return apiV1.get(`sales/definition/available`);
};

function getLegacySaleDefinitions() {
    return apiV1.get(`sales/definition/available/legacy`);
};

function getSaleDefinitionProductVariables(productNumber) {
    return apiV1.get(`sales/definition/vars/${productNumber}`);
}

export {
    getSaleDefinitions,
    getLegacySaleDefinitions,
    getSaleDefinitionProductVariables
}