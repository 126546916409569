import axios from "axios";

let BASE_API_URL = "http://localhost:8080";

if (window.origin.indexOf('live-test.servicepanel.co.za') != -1){
    BASE_API_URL = "https://live-test-api.servicepanel.co.za";
} else if (window.origin.indexOf('adept.servicepanel.co.za') != -1){
    BASE_API_URL="https://api.servicepanel.co.za";
} else {
    console.warn('Could not determine env, defaulting to local');
};

const apiV1 = axios.create({
    baseURL: `${BASE_API_URL}/api/v1/`,
});  

const apiV2 = axios.create({
    baseURL: `${BASE_API_URL}/api/v2/`,
}); 

const apiV3 = axios.create({
    baseURL: `${BASE_API_URL}/api/v3/`,
});  

apiV1.interceptors.request.use((config) => {
    const aimsToken = localStorage.getItem("aims-token");

    if (aimsToken) {
        config.headers["Authorization"] = `Bearer ${aimsToken}`;
    };
    
    return config;
});

apiV2.interceptors.request.use((config) => {
    const aimsToken = localStorage.getItem("aims-token");

    if (aimsToken) {
        config.headers["Authorization"] = `Bearer ${aimsToken}`;
    };
    
    return config;
});

apiV3.interceptors.request.use((config) => {
    const aimsToken = localStorage.getItem("aims-token");

    if (aimsToken) {
        config.headers["Authorization"] = `Bearer ${aimsToken}`;
    };
    
    return config;
});


export { apiV1, apiV2, apiV3 }

    